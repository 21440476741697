<template>
  <div id="editPage">
    <div class="flex justify-end">
      <dropdown
          reposition
          searchable
          class="filter-dropdown h-10 ml-auto"
          placeholder="Dashboards"
          :options="dashboardOptions"
          v-model="selectedDashboard"
          :colors="{
            text: '#282828',
            bg: 'white',
            svg: '#282828',
            border: '#e8e8e8',
          }"
          :limit="1"
          width="192px"
          minWidth="102px"
          :config="{ label: 'labelName', trackBy: 'dashboard_id' }"
          @input="selectDashboard"
        />
    </div>
    <!-- <button class="my-3 px-5 py-2 text-sm text-white border-0 outline-none ml-2 bg-blue-600 hover:bg-brand-hover font-medium rounded-lg cursor-pointer  drop-shadow-sm" @click=routeBack()>Back</button> -->
    <BiDashboardView
      v-if="dashboardId"
      class="mt-3"
      :dashboardId="dashboardId"
    />
  </div>
</template>

<script>
import dropdown from "@/components/dropdown-base";
import axios from "@/axios"
import { BiDashboardView } from "@shared/dashboard-components/dashboard-view";

export default {
  name: "Bi-Dashboard-View",
  data() {
    return {
      dashboardOptions:[],
      selectedDashboard: []
    };
  },
  components: {
    dropdown,
    BiDashboardView,
  },

  computed: {
    dashboardId() {
      return this.$route.params.id;
    },
  },

  async mounted() {
    await this.getTenantDashboards();
  },
  methods: {
    selectDashboard() {
       if (this.selectedDashboard?.name != 'Default Dashboard') {
          this.$router.push({
            name: "Bi-Dashboard-View",
            params: {
              id: this.selectedDashboard.dashboard_id,
            },
          });
        } else {
          this.$router.push({
            path: "default-dashboard",
            name:"Dashboard - Page 2",
            params: {
              id: process.env.VUE_APP_DEFAULT_DASHBOARD_KEY,
            },
          });
          
        }
    },
    async getTenantDashboards() {
      try {
        const { data } = await axios.get("/dashboard/assigned-dashboards");
        this.dashboardOptions = data.dashboards
        .map((dashboard) => {
          let bbDashboard = dashboard;
          if (dashboard.name.includes("BB_")) {
            bbDashboard.labelName = dashboard.name.split("_")[1];
          } else {
            bbDashboard.labelName = dashboard.name;
          }

          bbDashboard.name = dashboard.name;
          return bbDashboard;
        });
        if (this.$route.params.id) {
          this.selectedDashboard = this.dashboardOptions.find((res)=>res.dashboard_id==this.$route.params.id)
        }
        else {
        this.selectedDashboard = this.dashboardOptions?.[0];
        }
        if (this.selectedDashboard?.name != 'Default Dashboard') {
          this.$router.push({
            name: "Bi-Dashboard-View",
            params: {
              id: this.selectedDashboard.dashboard_id,
            },
          });
        }
        else {
          this.$router.push({
            name: "default-dashboard"
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    routeBack() {
      this.$router.push({ name: "Dashboard - Page 2" });
    },
  },
};
</script>
<style scoped lang="scss">

#editPage {
  width: 100%;
  height: 100%;
}
</style>
